import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import classes from "./Home.module.css";
import {
  Button,
  Col,
  Row,
  Collapse,
  Select,
  Form,
  Input,
  Space,
  SelectProps,
  Steps,
  notification,
} from "antd";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../common/ArrowsForCarousel";
import {
  CheckOutlined,
  SettingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import requestIcon from "../../img/request.png";
import searchIcon from "../../img/search.png";
import teams from "../../img/Microsoft_Office_Teams.png";
import powerPoint from "../../img/Microsoft_Office_PowerPoint.png";
import sway from "../../img/Microsoft_Office_Sway.png";
import oneNote from "../../img/Microsoft_Office_OneNote.png";
import visio from "../../img/Microsoft_Office_Visio.png";
import planner from "../../img/Microsoft_Office_Planner.png";
import sharePoint from "../../img/Microsoft_Office_SharePoint.png";
import toDo from "../../img/Microsoft_Office_ToDo.png";
import oneDrive from "../../img/Microsoft_Office_OneDrive.png";
import outlook from "../../img/Microsoft_Office_Outlook.png";
import excel from "../../img/Microsoft_Office_Excel.png";
import word from "../../img/Microsoft_Office_Word.png";
import photoDirector from "../../img/Microsoft_Office_PhotoDirector.png";
import powerDirector from "../../img/Microsoft_Office_PowerDirector.png";
import audioDirector from "../../img/Microsoft_Office_AudioDirector.png";
import colorDirector from "../../img/Microsoft_Office_ColorDirector.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AnnouncementsCarousel from "./components/AnnouncementsCarousel";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import Spinner from "../../common/Spinner";
import danger from "../../img/danger.png";
import axios from "axios";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [size, setSize] = useState<SizeType>("large");
  const { Panel } = Collapse;
  const { Option } = Select;
  const {
    isOpenSearch,
    isOpenSearchResults,
    isOpenResults,
    isOpenFailed,
    toggleSearch,
    toggleSearchResults,
    toggleResults,
    toggleFailed,
  } = useModal();
  const [current, setCurrent] = useState<number>(2);
  const [notification, setNotification] = useState<string>("");
  const [color, setColor] = useState<string>("");

  const [application, setApplication] = useState<{
    application_code: string;
    company_name: string;
    tax_id: string;
    status: string;
    status_id: number;
    date_submitted: string;
    date_approved: string;
  }>({
    application_code: "",
    company_name: "",
    tax_id: "",
    status: "",
    status_id: -1,
    date_submitted: "",
    date_approved: "",
  });
  const [currentStatus, setCurrentStatus] = useState<string>(
    application.status
  );

  const dateString = application.date_submitted;
  const [year, month, day] = dateString.split("/");
  const formattedDate = `${day}/${month}/${year}`;

  const handleButtonClick = (step: number) => {
    switch (step) {
      case 1:
        toggleSearch();
        toggleSearchResults();
        break;
      case 2:
        toggleSearchResults();
        toggleResults();
        break;
      case 3:
        toggleResults();
        toggleFailed();
        break;
      default:
        break;
    }
  };

  const text1 = `Το πρόγραμμα αποσκοπεί στη στήριξη των υφιστάμενων ή υπό σύσταση επιχειρήσεων, προκειμένου αυτές να αξιοποιήσουν σύγχρονα σετ ψηφιακών εργαλείων, ώστε να βελτιώσουν την εξωστρέφεια και ανταγωνιστικότητά τους.  Τα εν λόγω εργαλεία θα διατίθενται ελεύθερα επιτρέποντας αφενός την άμεση αξιοποίησή τους, με μηδενικό κόστος για την επιχείρηση, αφετέρου την ομαλή ενσωμάτωσή τους στην παραγωγική διαδικασία επιτυγχάνοντας μεταξύ άλλων την ενίσχυση της εξωστρέφειας και της ανταγωνιστικότητάς τους. `;
  const text2 = <>
    Το πρόγραμμα παρέχει στις επιχειρήσεις ένα πλήρες πακέτο ψηφιακών εργαλείων, το οποίο περιλαμβάνει:
    <br/>• Teams (Office 365 E1, Microsoft) για chat, τηλεδιάσκεψη και τηλεκπαίδευση. 

    <br/>• PowerPoint και Sway για παρουσιάσεις και ψηφιακό marketing. 

    <br/>• Planner, To Do και OneDrive για προγραμματισμό, παρακολούθηση χρόνου εργασίας και διαμοιρασμό κοινού χώρου. 

    <br/>• Exchange/Outlook, Excel και Word για αλληλογραφία, λογιστικά φύλλα και επεξεργασία κειμένου. 

    <br/>• PhotoDirector και PowerDirector (Director Suite 365, Cyberlink) για επεξεργασία εικόνας και βίντεο. 
  </>;
  const text3 = `Δικαίωμα συμμετοχής στη δράση έχουν επιχειρήσεις με έδρα ή και υποκατάστημα/τα στον Δήμο Αθηναίων. `;
  const text4 = `Για να υποβάλετε αίτηση συμμετοχής στο πρόγραμμα, θα πρέπει να επισκεφθείτε την ιστοσελίδα digitaltools.thisisathens.org και πατώντας στην ένδειξη «Αίτηση συμμετοχής ωφελούμενου»`;
  const text5 = `Κάθε ενεργοποίηση άδειας έχει διάρκεια ενός έτους (12μηνη συνδρομή) `;

  type ExpandIconPosition = "start" | "end";

  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");

  const onFinish = async (values: any) => {
    const url = `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Search?app_form_code=${values.ap_number}&tax_id=${values.afm}`;
    setNotification("");
    const response = await axios.get(url);
    console.log(response);

    if (response.data[0]) {
      toggleSearch();
      toggleResults();
      setApplication(response.data[0]);
      if (response.data[0].status_id == 4) {
        setNotification(
          "Λυπούμαστε, αλλά δεν πληρούνται τα βασικά κριτήρια συμμετοχής στο πρόγραμμα. Ευχαριστούμε που υποβάλατε την αίτησή σας."
        );
      } else if (response.data[0].status_id == 2) {
        setNotification(
          "Η αίτησή σας έχει εγκριθεί. Στα στοιχεία επικοινωνίας που έχετε δηλώσει, σας έχουν σταλεί οδηγίες για τη σύνδεση στο λογαριασμό σας. Σε περίπτωση που δεν έχετε λάβει σχετικό μήνυμα, παρακαλώ επικοινωνήστε μαζί μας."
        );
      }
    } else {
      toggleSearch();
      toggleFailed();
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const items = [
    {
      title: "Υποβολή",
      description: formattedDate,
    },
    {
      title: "Σε εξέλιξη",
    },
    {
      title:
        application.status == "Απορρίφθηκε"
          ? "Απορρίφθηκε"
          : application.status == "Εγκρίθηκε"
          ? "Εγκρίθηκε"
          : "Εγκρίθηκε / Απορρίφθηκε",
      description: currentStatus ? application.date_approved : null,
      icon:
        application.status == "Απορρίφθηκε" ? (
          <div className={classes.closeOutlinedContainer}>
            <CloseOutlined
              style={{ color: "#ED5350", width: "42px", fontSize: "21px" }}
            />
          </div>
        ) : application.status == "Εγκρίθηκε" ? (
          <div className={classes.checkOutlinedContainer}>
            <CheckOutlined
              style={{ color: "#33C481", width: "42px", fontSize: "21px" }}
            />
          </div>
        ) : null,
    },
  ];

  const getCurrentStep = () => {
    if (!notification && current == 2 && currentStatus == "Απορρίφθηκε") {
      setNotification(
        "Λυπούμαστε, αλλά δεν πληρούνται τα βασικά κριτήρια συμμετοχής στο πρόγραμμα. Ευχαριστούμε που υποβάλατε την αίτησή σας."
      );
      setColor("#ED5350");
    } else if (!notification && current == 2 && currentStatus == "Εγκρίθηκε") {
      setNotification(
        "Η αίτησή σας έχει εγκριθεί. Στα στοιχεία επικοινωνίας που έχετε δηλώσει, σας έχουν σταλεί οδηγίες για τη σύνδεση στο λογαριασμό σας. Σε περίπτωση που δεν έχετε λάβει σχετικό μήνυμα, παρακαλώ επικοινωνήστε μαζί μας."
      );
      setColor("#33C481");
      setCurrent(current + 1);
      return current + 1;
    }
    return current;
  };

  return (
    <>
      <HelmetComponent
        title={t("Αναπτυξιακή Αθήνας") || undefined}
        descrition={t("Αναπτυξιακή Αθήνας") || undefined}
      />

      {/* step={1} = Search type modal */}
      <Modal isOpen={isOpenSearch} toggle={toggleSearch} step={1}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={toggleSearch}>
            x
          </button>
        </div>
        <div className={classes.message}>
          <h2>Βρείτε την αίτηση σας εδώ</h2>
          <Form
            form={form}
            layout="horizontal"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className={classes.formItemContainer}>
              <Form.Item
                label="Αριθμός Αίτησης"
                name="ap_number"
                className={classes.formItem}
                rules={[
                  { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                ]}
              >
                <Input className={classes.input} />
              </Form.Item>
              <Form.Item
                label="ΑΦΜ"
                name="afm"
                className={classes.formItem}
                rules={[
                  { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                ]}
              >
                <Input className={classes.input} />
              </Form.Item>
            </div>
            <div className={classes.buttons}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  className={classes.submit}
                  htmlType="submit"
                >
                  Αναζήτηση
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal isOpen={isOpenSearchResults} toggle={toggleSearchResults} step={2}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={toggleSearchResults}>
            x
          </button>
        </div>
        <Spinner />
        <div className={classes.message}>
          <p>Παρακαλούμε περιμένετε λίγο όσο ερευνούμε την αίτησή σας.</p>

          <div className={classes.buttons}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                className={classes.submit}
                htmlType="submit"
                onClick={() => handleButtonClick(2)}
              >
                Αναζήτηση
              </Button>
            </Form.Item>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isOpenResults} toggle={toggleResults} step={3}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={toggleResults}>
            x
          </button>
        </div>
        <div className={classes.message} style={{ maxWidth: 628 }}>
          <h4>Στοιχεία Αίτησης</h4>

          <Row className={classes.details}>
            <Col xs={24} sm={24} lg={8} xl={8} className={classes.detailsTitle}>
              Αριθμός Αίτησης
            </Col>
            <Col
              xs={24}
              sm={24}
              lg={16}
              xl={16}
              className={classes.detailsContent}
            >
              {application.application_code}
            </Col>
            <Col xs={24} sm={24} lg={8} xl={8} className={classes.detailsTitle}>
              ΑΦΜ
            </Col>
            <Col
              xs={24}
              sm={24}
              lg={16}
              xl={16}
              className={classes.detailsContent}
            >
              {application.tax_id}
            </Col>
          </Row>

          <h4 style={{ marginTop: 40 }}>Κατάσταση Αίτησης</h4>
          <Steps
            current={getCurrentStep()}
            labelPlacement="vertical"
            items={items}
          />
          {console.log("currentStatus: ", currentStatus)!}

          {notification ? (
            <div
              className={classes.notificationContainer}
              style={{
                color: `${application.status_id == 2 ? "#33C481" : "#ED5350"}`,
              }}
            >
              <p>{notification}</p>
            </div>
          ) : null}
          <br />
        </div>
      </Modal>

      <Modal isOpen={isOpenFailed} toggle={toggleFailed} step={4}>
        <div className={classes.overlay_header}>
          <button className={classes.cross} onClick={toggleFailed}>
            x
          </button>
        </div>
        <div className={classes.dangerContainer}>
          <img src={danger} alt="message" />
        </div>
        <div className={classes.message}>
          <p>
            Η αίτησή σας δε βρέθηκε. Παρακαλούμε ελέγξτε τα στοιχεία και
            δοκιμάστε ξανά ή επικοινωνήστε μαζί μας για βοήθεια.
          </p>
        </div>
      </Modal>

      <div className={classes.imageContainerWelcome}>
        <div className={classes.imageWelcome}>
          {/* <img src={imageWelcome} alt="Welcome Image" /> */}
          <div className={classes.imageWelcomeTitle}>
            <span>
              {t("Καλώς ήρθατε στoν Ψηφιακό Μετασχηματισμό Επιχειρήσεων")}
            </span>
          </div>
          <div className={classes.imageWelcomeDesc}>
            <span>
              {t(
                "Aναβαθμίστε την επιχείρησή σας σήμερα υποβάλλοντας αίτηση για να λάβετε τις προηγμένες ψηφιακές υπηρεσίες!"
              )}
            </span>
          </div>
          <div className={classes.imageWelcomeDesc2}>
            <span>
              {t(
                "Το έργο υλοποιείται στο πλαίσιο της ενταγμένης Πράξης «Ψηφιακός Μετασχηματισμός επιχειρήσεων με έδρα στο Δήμο Αθηναίων» με κωδικό ΟΠΣ (MIS) 6018412"
              )}
            </span>
          </div>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.benefitsContainer}>
          <div className={classes.requests}>
            <div className={classes.request}>
              <span className={classes.requestTitle}>
                {t("Αίτηση Συμμετοχής Ωφελούμενων")}
              </span>
              <span className={classes.requestDesc}>
                {t(
                  "Υποβάλετε την αίτηση συμμετοχής σας στο πρόγραμμα ακολουθώντας τις οδηγίες συμπλήρωσης"
                )}
              </span>
              <Button
                className={classes.requestButton}
                type="primary"
                shape="round"
                size={size}
                onClick={() =>
                  window.localStorage.getItem("user")
                    ? navigate("/form")
                    : navigate("/loginuser")
                }
              >
                <img
                  className={classes.requestIcon}
                  src={requestIcon}
                  alt="application"
                />
                &nbsp;&nbsp;{t("Αίτηση")}
              </Button>
            </div>
            <div className={classes.request}>
              <span className={classes.requestTitle}>
                {t("Yποβληθείσα Αίτηση")}
              </span>
              <span className={classes.requestDesc}>
                {t(
                  "Αναζήτηση υποβοληθείσας αίτησης για ανάρτηση/διόρθωση δικαιολογητικών ή έλεγχο αποτελέσματος"
                )}
              </span>
              <Button
                className={classes.searchButton}
                style={{ background: "#1B5279" }}
                type="primary"
                shape="round"
                size={size}
                onClick={toggleSearch}
              >
                <img
                  className={classes.requestIcon}
                  src={searchIcon}
                  alt="search"
                />
                &nbsp;&nbsp;{t("Αναζήτηση")}
              </Button>
            </div>
          </div>
          <div className={classes.benefits}>
            <span className={classes.benefitsTitle}>
              {t("Παροχές Προγράμματος")}
            </span>
            <br />
            <br />
            <div className={classes.benefit}>
              <Row>
                <Col xs={2} sm={2} md={1} lg={1}>
                  <div className={classes.benefitCol}>
                    <CheckOutlined style={{ color: "#7BB6E1" }} />
                  </div>
                </Col>
                <Col xs={22} sm={10} md={10} lg={13}>
                  <span className={classes.benefitTitle}>
                    {t("Εφαρμογή chat, τηλεδιάσκεψης και τηλεκπαίδευσης")}
                  </span>
                  <br />
                  <span className={classes.benefitTitle}>
                    {t("Teams - Office 365 E1 / Microsoft")}
                  </span>
                </Col>
                <Col xs={24} sm={10} md={12} lg={10}>
                  <img
                    className={classes.serviceIcon}
                    src={teams}
                    alt="Microsoft Office Teams"
                  />
                </Col>
              </Row>
            </div>
            <div className={classes.benefit}>
              <Row>
                <Col xs={2} sm={2} md={1} lg={1}>
                  <div className={classes.benefitCol}>
                    <CheckOutlined style={{ color: "#7BB6E1" }} />
                  </div>
                </Col>
                <Col xs={22} sm={10} md={10} lg={13}>
                  <span className={classes.benefitTitle}>
                    {t("Εφαρμογές παρουσιάσεων & ψηφιακού μάρκετινγκ")}
                  </span>
                  <br />
                  <span className={classes.benefitTitle}>
                    {t("Power Point, Sway - Office 365 E1 / Microsoft")}
                  </span>
                </Col>
                <Col xs={24} sm={10} md={13} lg={10}>
                  <img
                    className={classes.serviceIcon}
                    src={sway}
                    alt="Microsoft Office Sway"
                  />
                  <img
                    className={classes.serviceIcon}
                    src={powerPoint}
                    alt="Microsoft Office PowerPoint"
                  />
                </Col>
              </Row>
            </div>
            <div className={classes.benefit}>
              <Row>
                <Col xs={2} sm={2} md={1} lg={1}>
                  <div className={classes.benefitCol}>
                    <CheckOutlined style={{ color: "#7BB6E1" }} />
                  </div>
                </Col>
                <Col xs={22} sm={10} md={10} lg={13}>
                  <span className={classes.benefitTitle}>
                    {t(
                      "Εφαρμογή προγραμματισμού, παρακολούθησης χρόνου εργασίας και διαμοιρασμού κοινού χώρου"
                    )}
                  </span>
                  <br />
                  <span className={classes.benefitTitle}>
                    {t("Planer, To Do, One Drive - Office 365 E1 / Microsoft")}
                  </span>
                </Col>
                <Col xs={24} sm={10} md={13} lg={10}>
                  <img
                    className={classes.serviceIcon}
                    src={planner}
                    alt="Microsoft Office Planer"
                  />
                  <img
                    className={classes.serviceIcon}
                    src={toDo}
                    alt="Microsoft Office To Do"
                  />
                  <img
                    className={classes.serviceIcon}
                    src={oneDrive}
                    alt="Microsoft Office One Drive"
                  />
                </Col>
              </Row>
            </div>
            <div className={classes.benefit}>
              <Row>
                <Col xs={2} sm={2} md={1} lg={1}>
                  <div className={classes.benefitCol}>
                    <CheckOutlined style={{ color: "#7BB6E1" }} />
                  </div>
                </Col>
                <Col xs={22} sm={10} md={10} lg={13}>
                  <span className={classes.benefitTitle}>
                    {t(
                      "Εφαρμογές αλληλογραφίας, λογιστικών φύλλων κι επεξεργασίας κειμένου"
                    )}
                  </span>
                  <br />
                  <span className={classes.benefitTitle}>
                    {t("Word, Excel, Outlook - Office 365 E1 / Microsoft")}
                  </span>
                </Col>
                <Col xs={24} sm={10} md={13} lg={10}>
                  <img
                    className={classes.serviceIcon}
                    src={word}
                    alt="Microsoft Office Word"
                  />
                  <img
                    className={classes.serviceIcon}
                    src={excel}
                    alt="Microsoft Office Excel"
                  />
                  <img
                    className={classes.serviceIcon}
                    src={outlook}
                    alt="Microsoft Office Outlook"
                  />
                </Col>
              </Row>
            </div>
            <div className={classes.benefit}>
              <Row>
                <Col xs={2} sm={2} md={1} lg={1}>
                  <div className={classes.benefitCol}>
                    <CheckOutlined style={{ color: "#7BB6E1" }} />
                  </div>
                </Col>
                <Col xs={22} sm={10} md={10} lg={13}>
                  <span className={classes.benefitTitle}>
                    {t("Εφαρμογές επεξεργασίας εικόνας & video")}
                  </span>
                  <br />
                  <span className={classes.benefitTitle}>
                    {t(
                      "PhotoDirector, PowerDirector - Director Suite 365 / Cyberlink"
                    )}
                  </span>
                </Col>
                <Col xs={24} sm={10} md={13} lg={10}>
                  <img
                    className={classes.serviceIcon}
                    src={photoDirector}
                    alt="Microsoft Office PhotoDirector"
                  />
                  <img
                    className={classes.serviceIcon}
                    src={powerDirector}
                    alt="Microsoft Office PowerDirector"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className={classes.benefitsExtra}>
            <span
              className={classes.benefitsTitle}
              style={{ padding: "0 25px" }}
            >
              {t("Επιπλέον Παροχές")}
            </span>
            <br />
            <br />
            <div
              className={classes.benefitsWrapper}
              style={{ padding: " 0 25px" }}
            >
              <div className={classes.benefit} style={{ borderTop: 0 }}>
                <Row>
                  <Col xs={2} sm={2} md={1} lg={1}>
                    <div className={classes.benefitCol}>
                      <CheckOutlined style={{ color: "#7BB6E1" }} />
                    </div>
                  </Col>
                  <Col xs={22} sm={10} md={10} lg={13}>
                    <span className={classes.benefitTitle}>
                      {t(
                        "Εφαρμογές καταγραφής σημειώσεων, διαγραμμάτων και διαδικτυακή πλατφόρμα συνεργασίας"
                      )}
                    </span>
                    <br />
                    <span className={classes.benefitTitle}>
                      {t(
                        "OneNote, Visio, SharePoint - Office 365 E1 / Microsoft"
                      )}
                    </span>
                  </Col>
                  <Col xs={24} sm={10} md={13} lg={10}>
                    <img
                      className={classes.serviceIcon}
                      src={visio}
                      alt="Microsoft Office Visio"
                    />
                    <img
                      className={classes.serviceIcon}
                      src={sharePoint}
                      alt="Microsoft Office SharePoint"
                    />
                    <img
                      className={classes.serviceIcon}
                      src={oneNote}
                      alt="Microsoft Office OneNote"
                    />
                  </Col>
                </Row>
              </div>
              <div className={classes.benefit}>
                <Row>
                  <Col xs={2} sm={2} md={1} lg={1}>
                    <div className={classes.benefitCol}>
                      <CheckOutlined style={{ color: "#7BB6E1" }} />
                    </div>
                  </Col>
                  <Col xs={22} sm={10} md={10} lg={13}>
                    <span className={classes.benefitTitle}>
                      {t("Εφαρμογές επεξεργασίας ήχου & χρωμάτων")}
                    </span>
                    <br />
                    <span className={classes.benefitTitle}>
                      {t("AudioDirector, ColorDirector - Director Suite 365")}
                    </span>
                  </Col>
                  <Col xs={24} sm={10} md={13} lg={10}>
                    <img
                      className={classes.serviceIcon}
                      src={audioDirector}
                      alt="Microsoft Office Visio"
                    />
                    <img
                      className={classes.serviceIcon}
                      src={colorDirector}
                      alt="Microsoft Office SharePoint"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.announcementsContainer}>
        <div
          className={classes.container}
          style={{ background: "transparent" }}
        >
          <div className={classes.announcements}>
            <span className={classes.mainTitle}>{t("Ανακοινώσεις")}</span>
            <Link to="/announcements">
              <span
                className={classes.announcementsBtn}
                style={{ float: "right" }}
              >
                {t("Δες τα όλα")}
              </span>
            </Link>
            <AnnouncementsCarousel />
          </div>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.faqContainer}>
          <span className={classes.benefitsTitle}>
            {t("Βρείτε τις απαντήσεις που χρειάζεστε")}
          </span>
          <br />
          <br />
          <Collapse
            className={classes.faq}
            onChange={onChange}
            expandIconPosition={expandIconPosition}
          >
            <Panel
              header={t(
                "Ποιος είναι ο στόχος του προγράμματος;"
              )}
              key="1"
            >
              <div style={{whiteSpace: 'pre-wrap'}}>{text1}</div>
            </Panel>
            <Panel 
              header={t("Ποια ψηφιακά εργαλεία είναι διαθέσιμα μέσω του προγράμματος;")} 
              key="2"
            >
              <div>{text2}</div>
            </Panel>
            <Panel
              header={t("Ποιες επιχειρήσεις μπορούν να συμμετάσχουν στο πρόγραμμα; ")}
              key="3"
            >
              <div>{text3}</div>
            </Panel>
            <Panel
              header={t(
                "Πώς μπορώ να κάνω αίτηση στο πρόγραμμα; "
              )}
              key="4"
            >
              <div>{text4}</div>
            </Panel>
            <Panel
              header={t(
                "Ποια είναι η διάρκεια διάθεσης των προϊόντων και υπηρεσιών του έργου;"
              )}
              key="5"
            >
              <div>{text5}</div>
            </Panel>
          </Collapse>
        </div>
        <br />
      </div>
    </>
  );
};

export default Home;
