import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import classes from "./AnnouncementList.module.css";
import {
  Modal,
  Input,
  Table,
  DatePicker,
  Switch,
} from "antd";
import edit_transparent_blue from "../../img/edit_transparent_blue.png";
import red_ellipse from "../../img/red_ellipse.png";
import green_ellipse from "../../img/green_ellipse.png";
import exclamation from "../../img/exclamation.png";
import search_fill from "../../img/search_fill.png";
import { useState, useEffect, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import { RangeValue } from "rc-picker/lib/interface";
import type { TableRowSelection } from "antd/es/table/interface";
import type { ColumnsType } from "antd/es/table";
import "jspdf-autotable";
import "dayjs/locale/el";
import locale from "antd/es/date-picker/locale/el_GR";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import styled from "styled-components";

const { RangePicker } = DatePicker;

interface NewsItem {
  date_submitted: string;
}

interface DataType2 {
  key: string;
  recid: string;
  date_submitted: string;
  title: string; // Change the key property to application_code
  user_submited: string;
  enabled: string;
}

const customNoDataLocale = {
  emptyText: (
    <div className={classes.noData}>
      <img
        src={exclamation}
        alt="exclamation"
        className={classes.exclamation}
      />
      <p>Δεν υπάρχουν εγγραφές</p>
    </div>
  ),
};

function AnnouncementList() {
  const { t } = useTranslation();

  const [news, setNews] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any>([])

  const navigate = useNavigate()

  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);

  const columns2: ColumnsType<DataType2> = [
    {
      title: "Ημερομηνία Υποβολής",
      dataIndex: "date_submitted",
      key: "date_submitted",
      width: 190,
      sorter: (a, b) => {
        return dayjs(a.date_submitted, "YYYY/MM/DD").isBefore(
          dayjs(b.date_submitted, "YYYY/MM/DD")
        )
          ? 1
          : -1;
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Τίτλος",
      dataIndex: "title",
      key: "title",
      width: 168,
    },
    {
      title: "Συντάκτης",
      dataIndex: "user_submited",
      key: "user_submited",
      width: 168,
    },
    {
      title: "Κατάσταση Ανακοίνωσης",
      dataIndex: "enabled",
      key: "enabled",
      width: 215,
      render: (enabled: string, record: any) => {
       if (enabled) {
          return (
            <div className={classes.statusContainer}>
              <StyledSwitch defaultChecked onChange={() => handleSwitch({id: record.recid, enabled})}/>
              {/* <img src={green_ellipse} alt={enabled} />  */}
              <p style={{paddingLeft: '1rem'}}>Ενεργή</p>
            </div>
          );
        } else return (
          <div className={classes.statusContainer}>
            <StyledSwitch onChange={() => handleSwitch({id: record.recid, enabled})}/>
            {/* <img src={red_ellipse} alt={enabled} />  */}
            <p style={{paddingLeft: '1rem'}}>Ανενεργή</p>
          </div>
        );
      },
    },
    {
      title: "Επεξεργασία Ανακοίνωσης",
      dataIndex: "actions",
      width: 193,
      key: "actions",
      render: (_, record: DataType2) => (
        <div
          className={classes.editWrap}
          onClick={() => navigate(`/edit_announcement/${record.recid}`)}
        >
          <img
            className={classes.editTransparentBlue}
            src={edit_transparent_blue}
            alt=""
          />
        </div>
      ),
    },
  ];

  const token = window.localStorage.getItem("token");

  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<DataType2[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const tableRef = useRef<any>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    fetchNews();
  }, []);

  useEffect(() => {
    if(news.length) {
      const newDataSource = news.map((article: any, index: number) => {
        return {
          key: index,
          ...article
        }
      })
      setDataSource(newDataSource)
    } 
  }, [news])

  const handleSearchInputChange = async (e: any) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.trim() === "") {
      return;
    }

    setIsSearching(true);
  };

  const handleSwitch = async ({id, enabled}: any) => {
    if(!enabled) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_NAME}/api/News/Enable/${id}`
        );

        console.log("Switch", response)
        fetchNews()
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    } else {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_NAME}/api/News/Disable/${id}`
        );
        console.log("Switch", response)
        fetchNews()
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    }
  }
 
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType2[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection2: TableRowSelection<DataType2> = {
    onChange: onSelectChange,
    selectedRowKeys,
  };

  const renderStatus = (enabled: string) => {
    if (enabled) {
      return (
        <div className={classes.statusContainer}>
          <img src={green_ellipse} alt={enabled} /> <p style={{paddingLeft: '1rem'}}>Ενεργή</p>
        </div>
      );
    } else return (
      <div className={classes.statusContainer}>
        <img src={red_ellipse} alt={enabled} /> <p style={{paddingLeft: '1rem'}}>Ανενεργή</p>
      </div>
    );
  };

  // handel modal ok => delete news
  const handleOk = async () => {
    try {
      // Array to store all the Axios promises
      const updatePromises: Promise<any>[] = [];

      // Loop through each selected row and create a separate Axios request for each one
      selectedRows.forEach((row) => {
        const promise = axios.delete<any>(
          `${process.env.REACT_APP_SERVER_NAME}/api/News/Delete/${row.recid}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        updatePromises.push(promise);

        // setDataSource(dataSource.filter((data: any) => data.recid !== row.recid))
      });

      closeModal(); // Close the modal after the updates are done
      setSelectedRowKeys([]);
      fetchNews();
    } catch (error) {
      console.error("Error updating status:", error);
    }

    fetchNews();
  };

  const fetchNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_NAME}/api/News/GetAll`
      );
      const newsData: NewsItem[] = response.data;
      newsData.sort(
        (a, b) =>
          new Date(b.date_submitted).getTime() -
          new Date(a.date_submitted).getTime()
      );
      setNews(newsData);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const onRangeChange = (
    values: RangeValue<Dayjs>,
    formatString: [string, string]
  ) => {
    if (values) {
      const [start, end] = values;
      const newStartDate = start ? start.startOf("day") : null;
      const newEndDate = end ? end.endOf("day") : null;
      setDateRange([newStartDate, newEndDate]);
    } else {
      setDateRange([null, null]);
    }
  };

  const showModal = () => {
    console.log(selectedRowKeys.length)
    if (selectedRowKeys.length) setModalVisible(true);
    else console.log("Error: Επιλέξτε αιτήσεις!");
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <HelmetComponent
        title={t("Αναπτυξιακή Αθήνας") || undefined}
        descrition={t("Αναπτυξιακή Αθήνας") || undefined}
      />
      <Modal
        open={modalVisible}
        onOk={handleOk}
        onCancel={closeModal}
        cancelText="Άκυρο"
        okText="Διαγραφή"
        className={classes.modal1Container}
        okButtonProps={{
          className: `${classes.submit}`,
          style: { backgroundColor: "#FF0000" },
        }}
        cancelButtonProps={{
          className: `${classes.submit}`,
          style: {
            backgroundColor: "transparent",
            color: "#767CA0",
            border: 0,
            boxShadow: "none",
          },
        }}
      >
        {selectedRows.length > 0 && (
          <div className={classes.modal1}>
            <h3>Επιλεγμένες ανακοινώσεις για διαγραφή</h3>
            <div>
              {selectedRows.map((row) => (
                <div key={row.recid}>
                  <div className={classes.statusRow}>
                    <p>{row.date_submitted}</p>
                    <p>{row.title}</p>
                    <div>{renderStatus(row.enabled)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
      {token != null ? (
        <div className={classes.containerAdmin}>
          <div className={classes.container}>
            <h2 style={{ fontWeight: 600, marginBottom: 60 }}>Ανακοινώσεις</h2>
              <button className={selectedRows.length === 0 ? classes.deleteButtonDisabled : classes.deleteButton} disabled={selectedRows.length === 0} onClick={() => showModal()}><DeleteOutlined style={{marginRight: '1rem'}}/> Διαγραφή Ανακοίνωσης</button>
              <button className={classes.addButton} onClick={() => navigate('/add_announcement')}><PlusOutlined style={{marginRight: '1rem'}}/> Προσθήκη Ανακοίνωσης</button>
              <div className={classes.innerRight}>
                <div className={classes.customSearchContainer}>
                  <Input
                    className={classes.search}
                    allowClear
                    placeholder="Αναζήτηση εδώ"
                    onChange={handleSearchInputChange}
                    style={{ width: 200 }}
                  />
                  <img
                    src={search_fill}
                    alt="Search Icon"
                    className={classes.searchIcon}
                  />
                </div>
                <RangePicker
                  style={{ marginBottom: 25 }}
                  className={classes.rangePicker}
                  // presets={rangePresets}
                  onChange={onRangeChange}
                  value={dateRange}
                  locale={locale}
                />
                <br />
                <br />
              </div>
              
              <Table
                id="tableToExport1"
                bordered
                ref={tableRef}
                className={classes.tableData}
                locale={customNoDataLocale}
                scroll={{ x: "max-content" }}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection2,
                  columnWidth: 0,
                }}
                columns={columns2}
                dataSource={dataSource}
              />
          </div>
        </div>
      ) : (
        ((window as Window).location = "./loginuser")
      )}
    </>
  );
}

const StyledSwitch = styled(Switch)`
:where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: #12d11a;
}
:where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover {
  background: rgb(1 237 11)
}
`

export default AnnouncementList;
