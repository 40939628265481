import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import classes from "./Contact.module.css";
import {
  Button,
  Col,
  Row,
  Collapse,
  Select,
  Checkbox,
  Form,
  Input,
  Space,
  SelectProps,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import message from "../../img/message.png";
import axios from "axios";

const options: SelectProps["options"] = [];
const { Option } = Select;

const Contact = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [consentChecked, setConsentChecked] = useState(false);

  const [select, setSelect] = useState<string>("");

  const onFinish = async (values: any) => {
    console.log(values);

    const formData = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: values.phone,
      comment: values.problem, // Since the TextArea field has no name, use [' '][' '] to access the value
      contact_type: select,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_NAME}/api/Contact/Add`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      // Handle success (e.g., show a success message or reset the form)
      toggleSearch(); // Assuming this closes the modal after successful submission
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show an error message to the user)
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log("Change:", e.target.value);
  };

  const handleChange = (value: any) => {
    setSelect(value);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setConsentChecked(e.target.checked);
  };

  const onReset = () => {
    form.resetFields();
    setSelect("");
    setConsentChecked(false);
  };

  const { isOpenSearch, toggleSearch } = useModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetComponent
        title={t("Αναπτυξιακή Αθήνας") || undefined}
        descrition={t("Αναπτυξιακή Αθήνας") || undefined}
      />
      <div className={classes.pageContainer}>
        {/* step={0} = Contact type modal */}
        <Modal isOpen={isOpenSearch} toggle={toggleSearch} step={0}>
          <div className={classes.overlay_header}>
            <button className={classes.cross} onClick={toggleSearch}>
              x
            </button>
          </div>
          <div className={classes.messageContainer}>
            <img src={message} alt="message" />
          </div>
          <div className={classes.message}>
            <p>
              Σας ευχαριστούμε που επικοινωνήσατε μαζί μας. Θα σας απαντήσουμε
              το συντομότερο δυνατό.
            </p>
          </div>
        </Modal>
        <div className={classes.container}>
          <div className={classes.fcontainer}>
            <h2>Επικοινωνήστε μαζί μας</h2>
            <span className={classes.subName}>
              * Τα πεδία με αυτή την ένδειξη είναι υποχρεωτικά.
            </span>
            <br />
            <br />
            <br />
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              // style={{ maxWidth: 714 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className={classes.formItemContainer}>
                <Form.Item
                  label="Όνομα*"
                  name="firstname"
                  className={classes.formItem}
                  rules={[
                    { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>
                <Form.Item
                  label="Επώνυμο*"
                  name="lastname"
                  className={classes.formItem}
                  rules={[
                    { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>
                <Form.Item
                  label="Email*"
                  name="email"
                  className={classes.formItem}
                  rules={[
                    {
                      type: "email",
                      message: "Δεν έχει συμπληρωθεί σωστά. Ξαναπροσπαθήστε",
                    },
                    {
                      required: true,
                      message: "Το πεδίο είναι υποχρεωτικό",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>
                <Form.Item
                  label="Τηλέφωνο*"
                  name="phone"
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Το επώνυμό σας είναι υποχρεωτικό",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>
              </div>

              <h2>Περιγράψτε μας το πρόβλημά σας</h2>
              <span className={classes.subName}>
                Πείτε μας πώς μπορούμε να σας βοηθήσουμε*
              </span>
              <div className={classes.formItemContainer}>
                <Form.Item
                  name="problem"
                  className={classes.formItem}
                  rules={[
                    { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                  ]}
                >
                  <Input.TextArea
                    className={classes.inputTextArea}
                    style={{ height: 248, resize: "none" }}
                    maxLength={1000}
                    showCount
                  />
                </Form.Item>
              </div>
              <span className={classes.subName}>
                Έχετε 1000 χαρακτήρες στην διάθεσή σας
              </span>

              <h2>Τα στοιχεία επικοινωνίας σας</h2>
              <span className={classes.subName}>
                Πώς να επικοινωνήσουμε μαζί σας;
              </span>
              <div className={classes.formItemContainer}>
                <Form.Item>
                  <Select
                    defaultValue={select}
                    onChange={handleChange}
                    className={classes.menuActivities} // Apply your custom CSS class here (if you're using CSS modules)
                    style={{ marginTop: 16, marginBottom: 50 }}
                    optionLabelProp="label" // Use this prop to customize the label rendering
                  >
                    <Option
                      label="Τηλέφωνο"
                      value="Τηλέφωνο"
                      style={{ fontSize: "18px" }}
                    >
                      Τηλέφωνο
                    </Option>
                    <Option
                      label="Email"
                      value="Email"
                      style={{ fontSize: "18px" }}
                    >
                      Email
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div className={classes.formItemContainer}>
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  style={{ marginBottom: 70 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (consentChecked) {
                          return Promise.resolve();
                        }
                        if (!getFieldValue("remember")) {
                          return Promise.reject(
                            new Error("Πρέπει να δώσετε τη συγκατάθεσή σας.")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Checkbox
                    className={classes.checkbox}
                    onChange={handleCheckboxChange}
                  >
                    Δηλώνω ότι συγκατατίθεμαι για τη συλλογή, τη χρήση και την
                    επεξεργασία των παραπάνω στοιχείων και προσωπικών δεδομένων,
                    σύμφωνα με τους όρους και τις προϋποθέσεις που θέτει η Αρχή
                    Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.
                  </Checkbox>
                </Form.Item>
              </div>

              <div className={classes.buttons}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Space>
                    <Row>
                      <Col>
                        <Button
                          htmlType="button"
                          onClick={onReset}
                          className={classes.reset}
                        >
                          Καθαρισμός
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          className={classes.submit}
                          htmlType="submit"
                        >
                          Αποστολή
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
