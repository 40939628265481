import React, { useState, useEffect, useContext } from "react";
import { Carousel } from "antd";
import type { PaginationProps } from "antd";
import classes from "./css/AnnouncementsCarousel.module.css";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../common/ArrowsForCarousel";
import { Link } from "react-router-dom";
import i18next from "i18next";
import LanguageContext from "../../../store/language-context";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import announcementImg from "../../../img/Announcement1.png";

const AnnouncementsCalendar: React.FC = () => {
  const { t } = useTranslation();
  const controller = new AbortController();
  const { signal } = controller;
  var currentLanguageCode = cookies.get("i18next") || "el";

  const languageCtx = useContext(LanguageContext);
  const [announcements, setAnnouncements] = useState<any[]>([]);
  const [current, setCurrent] = useState(0);

  // Function to collect data
  const getAnnouncements = async () => {
    const furl = `${process.env.REACT_APP_SERVER_NAME}/api/News/GetAll`;
    const response = await fetch(furl, {
      signal,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Data could not be fetched!");
    } else {
      return response.json();
    }
  };

  useEffect(() => {
    getAnnouncements()
      .then((data) => {
        setAnnouncements(data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page - 1);
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    swipe: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  };
  return (
    <div className={classes.carousel}>
      <Carousel {...settings}>
        {announcements.map((announcement) => (
          <div key={announcement["id"]}>
            <div className={classes.carouselslider}>
              <Link to={`/announcements/${announcement.recid}`}>
                <div className={classes.carouselimg}>
                  <img
                    src={
                      announcement.image_url
                        ? announcement.image_url
                        : announcementImg
                    }
                    style={{width: '100%', height: '100%'}}
                    alt={announcement["title"]}
                  />
                </div>
              </Link>

              <div className={classes.carouseltxt}>
                <div className={classes.text}>
                  <span className={classes.date}>
                    {formatDate(announcement.date_submitted)}
                  </span>
                  <Link to={`/announcements/${announcement.recid}`}>
                    <span className={classes.title}> {announcement.title}</span>
                  </Link>
                  <span
                    className={classes.description}
                    dangerouslySetInnerHTML={{ __html: announcement.content }}
                  ></span>
                </div>

                <Link to={`/announcements/${announcement.recid}`}>
                  <span className={classes.more}>{t("Περισσότερα")}</span>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default AnnouncementsCalendar;
