import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import classes from "./Announcements.module.css";
import { Col, Row } from "antd";
import announcementImg from "../../img/Announcement1.png";

interface NewsItem {
  date_submitted: string;
}

const Announcements = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState<any[]>([]);

  type ExpandIconPosition = "start" | "end";

  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_NAME}/api/News/GetAll`
        );
        const newsData: NewsItem[] = response.data;
        newsData.sort(
          (a, b) =>
            new Date(b.date_submitted).getTime() -
            new Date(a.date_submitted).getTime()
        );
        setNews(newsData);
        window.scrollTo(0, 0);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  console.log(news)

  return (
    <>
      <HelmetComponent
        title={t("Αναπτυξιακή Αθήνας") || undefined}
        descrition={t("Αναπτυξιακή Αθήνας") || undefined}
      />
      <div className={classes.container}>
        <div className={classes.benefitsContainer}>
          <div className={classes.benefits}>
            <span className={classes.benefitsTitle}>
              {t("Νέα / Ανακοινώσεις")}
            </span>
            <br />
            <br />
            {news.map((item) => (
              <div key={item.id} className={classes.benefit}>
                <Row>
                  <Col xs={24} sm={24} md={13} lg={10} xl={8}>
                    {/* Display news item image here */}
                    <Link to={`../announcements/${item.recid}`}>
                      <img
                        className={classes.imgNews}
                        src={item.image_url || announcementImg}
                        alt=""
                      />
                      <br />
                      <br />
                    </Link>

                    {/* </Col>
                                    <Col xs={0} sm={0} md={0} lg={1}> */}
                  </Col>
                  <Col xs={24} sm={24} md={11} lg={14} xl={16}>
                    <span className={classes.Date}>
                      {formatDate(item.date_submitted)}
                    </span>
                    <br />
                    <br />
                    <Link to={`../announcements/${item.recid}`}>
                      <span className={classes.Title}>{item.title}</span>
                      <br />
                      <br />
                    </Link>
                    <span
                      className={classes.Desc}
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    ></span>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Announcements;
