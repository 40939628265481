import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import classes from "./User.module.css";
import {
  Button,
  Col,
  Row,
  Collapse,
  Select,
  Checkbox,
  Form,
  Input,
} from "antd";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../common/ArrowsForCarousel";
import {
  SearchOutlined,
  CheckOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import announcementImg from "../../img/Announcement1.png";
import { useEffect, useState } from "react";
import userImg from "../../img/user.png";
import editIcon from "../../img/edit.png";
import saveIcon from "../../img/Save.png";
import axios from "axios";

const User = () => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    address: "",
    phoneNumber: "",
    email: "",
    username: window.localStorage.getItem("username"),
    password: "",
  });
  const [userID, setUserID] = useState<string>("");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const getAdminUserById = async () => {
    const id = await getAdminUserID();
    console.log("IDDD:", id);

    const adminUser = await axios.get(
      `${process.env.REACT_APP_SERVER_NAME}/api/Auth/GetAdminUserById/${id}`
    );
    console.log(adminUser.data);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      firstname: adminUser.data.firstname || "",
      lastname: adminUser.data.lastname || "",
      phoneNumber: adminUser.data.phoneNumber || "",
    }));
  };

  const getAdminUserID = async () => {
    const users = await axios.get(
      `${process.env.REACT_APP_SERVER_NAME}/api/Auth/GetAdminUsers`
    );
    console.log("users: ", users);
    console.log(
      "window.localStorage.getItem: ",
      window.localStorage.getItem("username")
    );

    const res = users.data.map((r: any) => console.log(r));

    const result = await users.data.filter(
      (user: any) => user.email == window.localStorage.getItem("username")
    );
    console.log(result);

    return result[0].id;
  };

  const onFinish = async (values: any) => {
    console.log(values);

    setFormValues(values);
    toggleEditMode();

    const id = await getAdminUserID();
    console.log("id: ", id);

    console.log(
      "values.name , ... : ",
      values.name,
      values.lastName,
      values.phone
    );

    // axios.post('https://map.social-network.gr/eatams/api/Auth/UpdateAdmin/' + { userID }, values)
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_NAME}/api/Auth/UpdateAdmin/${id}`,
        {
          firstname: values.firstname,
          lastname: values.lastname,
          phoneNumber: values.phoneNumber,
        },
        axiosConfig
      )
      .then((response) => {
        console.log(response);

        console.log(response.data);
        // Handle success
      })
      .catch((error) => {
        console.error(error);
        // Handle error
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    getAdminUserById();
    console.log(window.localStorage.getItem("user"));
  }, []);

  return (
    <>
      <HelmetComponent
        title={t("Αναπτυξιακή Αθήνας") || undefined}
        descrition={t("Αναπτυξιακή Αθήνας") || undefined}
      />
      <div className={classes.container}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={7} xl={6}>
            <img className={classes.userImg} src={userImg} alt="" />
            <h3>Διαχειριστής</h3>
          </Col>
          <Col xs={24} sm={24} md={24} lg={17} xl={18}>
            <Row>
              <Col xs={21} sm={9} md={8} lg={8} xl={6}>
                <h2 className={classes.heading}>Προφίλ μου</h2>
              </Col>
              <Col xs={24} sm={15} md={16} lg={16} xl={18}>
                {!editMode ? (
                  <img
                    className={classes.editIcon}
                    src={editIcon}
                    alt="edit"
                    onClick={toggleEditMode}
                  />
                ) : (
                  <img
                    className={classes.editIcon}
                    src={saveIcon}
                    alt="save"
                    onClick={() => form.submit()}
                  />
                )}
              </Col>
            </Row>
            <div className={classes.fcontainer}>
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={formValues}
              >
                <Row>
                  <Col xs={24} sm={7} md={6} lg={6} xl={8}>
                    <span className={classes.clabel}>Όνομα</span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={18} xl={16}>
                    <Form.Item
                      label=""
                      name="firstname"
                      className={classes.fitem}
                    >
                      {!editMode ? (
                        <span className={classes.cres}>
                          {formValues.firstname}
                        </span>
                      ) : (
                        <Input className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={6} xl={8}>
                    <span className={classes.clabel}>Επίθετο</span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={18} xl={16}>
                    <Form.Item
                      label=""
                      name="lastname"
                      className={classes.fitem}
                    >
                      {!editMode ? (
                        <span className={classes.cres}>
                          {formValues.lastname}
                        </span>
                      ) : (
                        <Input className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={6} xl={8}>
                    <span className={classes.clabel}>Διεύθυνση</span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={18} xl={16}>
                    <Form.Item
                      label=""
                      name="address"
                      className={classes.fitem}
                    >
                      {!editMode ? (
                        <span className={classes.cres}>
                          {t(formValues.address)}
                        </span>
                      ) : (
                        <Input className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={6} xl={8}>
                    <span className={classes.clabel}>
                      Τηλέφωνο επικοινωνίας
                    </span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={18} xl={16}>
                    <Form.Item
                      label=""
                      name="phoneNumber"
                      className={classes.fitem}
                    >
                      {!editMode ? (
                        <span className={classes.cres}>
                          {formValues.phoneNumber}
                        </span>
                      ) : (
                        <Input className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={6} xl={8}>
                    <span className={classes.clabel}>Ε-mail</span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={18} xl={16}>
                    <Form.Item label="" name="email" className={classes.fitem}>
                      {!editMode ? (
                        <span className={classes.cres}>{formValues.email}</span>
                      ) : (
                        <Input className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={6} xl={8}>
                    <span className={classes.clabel}>Username</span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={18} xl={16}>
                    <Form.Item
                      label=""
                      name="username"
                      className={classes.fitem}
                    >
                      {!editMode ? (
                        <span className={classes.cres}>
                          {formValues.username}
                        </span>
                      ) : (
                        <Input className={classes.mIdInput} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={6} xl={8}>
                    <span className={classes.clabel}>Password</span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={18} xl={16}>
                    <Form.Item
                      label=""
                      name="password"
                      className={classes.fitem}
                    >
                      {!editMode ? (
                        <span className={classes.cres}>*******</span>
                      ) : (
                        <Input.Password
                          placeholder=""
                          className={classes.mIdInput}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default User;
