import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Select, Space, Button, Dropdown, Menu } from "antd";
import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import { Link } from "react-router-dom";
import classes from "./Header.module.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import logo from "../../img/logo192.png";
import Navbar from "./Navbar";
import twitter from "../../img/twitter.png";
import etpa from "../../img/ETPA.png";
import login from "../../img/login.png";
import account from "../../img/account.png";
import LangSwitch from "../LangSwitch/LangSwitch";
import authService from "../../services/auth.service";
import { AuthContext } from "../../context/AuthContext";

interface User {
  email: string;
  // Add other properties of the user if available
}

const Header = () => {
  const handleChange = (value: string) => {
    i18n.changeLanguage(value);
  };
  const { t } = useTranslation();
  const [size, setSize] = useState<SizeType>("large");
  const [user, setUser] = useState<User | null>(null);
  const token = window.localStorage.getItem("token");
  const { setIsLoggedOut } = useContext(AuthContext);

  const items: MenuProps["items"] = [
    {
      label: "EN",
      key: "0",
    },
    {
      label: "ΕΛ",
      key: "1",
    },
  ];

  const handleLogout = () => {
    // Call the logout service or function here
    authService.logout();

    // Set the isLoggedOut state to true
    setIsLoggedOut(true);
  };
  const handleProfile = () => {
    authService.navigateProfile();
  };
  const menu = (
    <Menu style={{ padding: 0 }}>
      <Menu.Item key="login" style={{ padding: "2px 0" }}>
        <Button
          className={classes.login}
          type="primary"
          size={size}
          onClick={handleProfile}
        >
          {t("Προφίλ")}
        </Button>
      </Menu.Item>
      <Menu.Item key="logout" style={{ padding: "2px 0" }}>
        <Button
          className={classes.login}
          type="primary"
          size={size}
          onClick={handleLogout}
        >
          {t("Αποσύνδεση")}
        </Button>
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser: string | null = await window.localStorage.getItem(
          "user"
        );

        if (currentUser !== null && currentUser !== undefined) {
          const parsedUser: User = JSON.parse(currentUser);
          setUser(parsedUser);
        } else {
          setUser(null); // If currentUser is null, set the user state to null
        }
      } catch (error) {
        // Handle any error that occurred while fetching the user
        console.error(error);
      }
    };

    const refreshAccessToken = async () => {
      try {
        await authService.refreshAccessToken();
      } catch (error) {
        console.error("Error refreshing access token:", error);
      }
    };

    // Fetch user data when the component mounts
    fetchUser();

    // Set up an interval to refresh the access token every 30 seconds
    const intervalId = setInterval(() => {
      refreshAccessToken();
    }, 4 * 60 * 60 * 1000 + 58 * 60 * 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
      {window.localStorage.getItem("userType") == "admin" ? (
        <div className={[classes.headerAdmin, classes.flexContainer].join(" ")}>
          <div className={classes.containerBottom}>
            <div className={classes.Rectangle1Admin}>
              <Row
                justify="center"
                style={{ height: "100%", alignContent: "center" }}
              >
                <Col
                  lg={10}
                  md={10}
                  sm={12}
                  xs={18}
                  style={{ height: "100%", alignContent: "center" }}
                >
                  <Row style={{ height: "100%", alignContent: "center" }}>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={10}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div className={classes.app_logo}>
                        <Link to="/" state={{ page: "home" }}>
                          <img
                            src={process.env.PUBLIC_URL + "/img/logo.png"}
                            alt="logo"
                          />
                        </Link>
                      </div>
                    </Col>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.etpa}
                    ></Col>
                  </Row>
                </Col>
                <Col
                  lg={14}
                  md={14}
                  sm={12}
                  xs={6}
                  style={{ height: "100%", alignContent: "center" }}
                >
                  <Row style={{ height: "100%", alignContent: "center" }}>
                    <Col
                      lg={16}
                      md={16}
                      sm={16}
                      xs={16}
                      style={{ height: "100%", alignContent: "center" }}
                    >
                      <Navbar />
                    </Col>
                    <Col
                      lg={8}
                      md={8}
                      sm={8}
                      xs={8}
                      className={classes.loginCol}
                    >
                      {!user?.email && token == null ? (
                        <Link
                          to="/loginuser"
                          state={{ page: "login" }}
                          onClick={() => window.localStorage.clear()}
                        >
                          <Button
                            className={classes.notLogged}
                            type="primary"
                            icon={<img src={login} alt="login icon" />}
                            size={size}
                            onClick={() => window.localStorage.clear()}
                          >
                            {t("Είσοδος")}
                          </Button>
                        </Link>
                      ) : (
                        <Link to="/loginuser" state={{ page: "login" }}>
                          <Button
                            className={
                              window.localStorage.getItem("userType") == "admin"
                                ? classes.notLoggedAdmin
                                : classes.login
                            }
                            type="primary"
                            icon={<img src={login} alt="login icon" />}
                            size={size}
                            onClick={handleLogout}
                          >
                            {t("Έξοδος")}
                          </Button>
                        </Link>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* <div className={classes.topBarContainer}>
                        <div className={classes.topBar}>

                           
                            <LangSwitch color={'#FFF'} />
                        </div>
                    </div> */}
          <div className={[classes.header, classes.flexContainer].join(" ")}>
            <div className={classes.containerBottom}>
              <div className={classes.Rectangle1}>
                <Row
                  justify="center"
                  style={{ height: "100%", alignContent: "center" }}
                >
                  <Col
                    lg={10}
                    md={10}
                    sm={12}
                    xs={18}
                    style={{ height: "100%", alignContent: "center" }}
                  >
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={10}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className={classes.app_logo}>
                          <Link to="/" state={{ page: "home" }}>
                            <img src={process.env.PUBLIC_URL + "/img/logo.png"} alt="logo" />
                          </Link>
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.etpa}
                      >
                        <a href="#">
                          <img src={etpa} alt="external-link" />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={14}
                    md={14}
                    sm={12}
                    xs={6}
                    style={{ height: "100%", alignContent: "center" }}
                  >
                    <Row>
                      <Col
                        lg={14}
                        md={14}
                        sm={14}
                        xs={14}
                        style={{
                          height: "100%",
                          alignContent: "center",
                        }}
                      >
                        <Navbar />
                      </Col>
                      {/* <Col lg={2} md={2} sm={2} xs={2}>
                                                <div className={classes.socialSidebar}>
                                                    <Select
                                                        className={classes.selectLang}
                                                        defaultValue="gr"
                                                        size='large'
                                                        // onSelect={handleChange}
                                                        options={[
                                                            {
                                                                key: 1,
                                                                value: 'en',
                                                                label: 'EN'
                                                            },
                                                            {
                                                                key: 2,
                                                                value: 'gr',
                                                                label: 'ΕΛ'
                                                            }
                                                        ]}
                                                    ></Select>
                                                </div>
                                            </Col> */}
                      <Col
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        className={classes.loginCol}
                      >
                        {
                          !user?.email && token == null ? (
                            // <Dropdown overlay={menu} trigger={['hover']}>
                            //     <Button className={classes.login} type="primary" size={size} icon={<img src={login} alt="login icon" />}>
                            //         {/* You can set the default label for the dropdown button here */}
                            //         {t('Επιλογή')}
                            //     </Button>
                            // </Dropdown>
                            <Link
                              to="/loginuser"
                              state={{ page: "login" }}
                              onClick={() => window.localStorage.clear()}
                            >
                              <Button
                                aria-disabled
                                className={classes.notLogged}
                                type="primary"
                                icon={<img src={login} alt="login icon" />}
                                size={size}
                                onClick={() => window.localStorage.clear()}
                              >
                                {t("Είσοδος")}
                              </Button>
                            </Link>
                          ) : (
                            <Dropdown overlay={menu} trigger={["hover"]}>
                              <Button
                                className={classes.login}
                                style={{
                                  height: "64%",
                                  backgroundColor: "transparent",
                                  boxShadow: "none",
                                }}
                                type="primary"
                                size={size}
                              >
                                {/* You can set the default label for the dropdown button here */}
                                <img src={account} alt="Account" />
                              </Button>
                            </Dropdown>
                          )
                          // <Link to="/loginuser" state={{ page: 'login' }} >
                          //     <Button className={classes.login} type="primary" icon={<img src={login} alt='login icon' />} size={size}
                          //         onClick={handleLogout}>
                          //         {t('Έξοδος')}
                          //     </Button>
                          // </Link>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* <a href="#"><img src={twitter} className={classes.icon} alt="external-link" /></a>

                        <a href="#"><img src={facebook} className={classes.icon} alt="external-link" /></a> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
