import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import { useNavigate } from "react-router-dom";
import classes from "./Login.module.css"
import { Form, Input } from 'antd';

const LoginRequestPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    console.log('Values:', values);
    //if code is already returned check if there is a valid token
    const params = {
      username: values['username'],
      password: values['password']          
    };
    window.localStorage.setItem("username", params.username)
    //Login as backend administrator
    fetch(`${process.env.REACT_APP_SERVER_NAME}/api/Auth/Login`, {
      method: 'POST',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.access_token != null) {
          window.localStorage.setItem("token", data.access_token);
          window.localStorage.setItem("userType", "admin");

          (window as Window).location = './dashboard'
        }
      })
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <HelmetComponent title={t('Αναπτυξιακή Αθήνας') || undefined} descrition={t('Αναπτυξιακή Αθήνας') || undefined} />
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <div className={classes.formContainer1}>
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Όνομα χρήστη"
                name="username"
                className={classes.label}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}
                colon={false}
                required={false}
              >
                <Input className={classes.input} />
              </Form.Item>

              <Form.Item
                label="Κωδικός πρόσβασης"
                name="password"
                className={classes.label}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}
                colon={false}
                required={false}
              >
                <Input.Password className={classes.input} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <button
                  type="submit"
                  className={classes.loginBtn}
                  style={{
                    cursor: "pointer",
                    float: 'right'
                  }}
                >
                  Σύνδεση
                </button>
              </Form.Item>
              <span className={classes.forgot} onClick={() => navigate('/request-password')}>Έχω ξεχάσει το Συνθηματικό μου</span>
            </Form>

          </div>
        </div>
      </div>
    </>
  )
}

export default LoginRequestPassword;
