import React, { useEffect, useState } from 'react'
import classes from './CookiesBar.module.css'

const CookiesBar = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(true)
    },[])

    return (
        <div className={show ? classes.cookiesWrapperShow : classes.cookiesWrapper}>
            <h4 className={classes.text}>Αυτή η ιστοσελίδα χρησιμοποιεί <b>cookies</b> για την καλύτερη εμπειρία των χρηστών μας</h4>
            <btn className={classes.button} onClick={() => setShow(false)}>
                <h4>Το κατάλαβα</h4>
            </btn>
        </div>
    )
}

export default CookiesBar